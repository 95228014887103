<ng-container [ngSwitch]="translationKey">
  <span i18n *ngSwitchCase="''"></span>

  <!-- generic -->
  <span i18n="Translation.Notes@@server-error-please-check-your-data"
    *ngSwitchCase="GenericActions.GENERIC_SERVER_ERROR">Server error. Please check your data</span>
  <span i18n="Translation.Notes@@failed-to-perform-the-requested-action"
    *ngSwitchCase="GenericActions.FAILED_ACTION">Failed to perform the requested action</span>
  <span i18n="Translation.Notes@@saved-settings" *ngSwitchCase="GenericActions.GENERIC_SETTINGS_SAVED">Saved
    settings</span>
  <span i18n="Translation.Notes@@failed-to-apply-settings" *ngSwitchCase="GenericActions.GENERIC_SETTINGS_FAILED">Failed
    to apply settings</span>
  <span i18n="Translation.Notes@@copied-to-clipboard" *ngSwitchCase="GenericActions.GENERIC_COPIED_CLIPBOARD">Copied to
    clipboard</span>
  <span i18n="Translation.Notes@@failed-to-copy-to-clipboard" *ngSwitchCase="GenericActions.GENERIC_COPY_FAILED">Failed to
    copy to clipboard</span>

  <!-- variant list -->
  <span i18n="Translation.Notes@@unable-to-add-variant-to-report" *ngSwitchCase="'Unable to add variant to report'">Unable to add variant to report</span>

  <!-- app -->
  <div *ngSwitchCase="'FILES_BEING_UPLOADED'">
    <div>
      <strong i18n="Translation.Notes@@translation-case">
        <app-ruo-ivd-mapper>
          <ng-container IVD>Case</ng-container>
          <ng-container RUO>Research case</ng-container>
        </app-ruo-ivd-mapper>
      </strong>
      <strong i18n="Translation.Notes@@created" *ngIf="!toast.data.caseFileUploadStatus.editMode"> created</strong>
      <strong i18n="Translation.Notes@@saved" *ngIf="toast.data.caseFileUploadStatus.editMode"> saved</strong><strong>: {{toast.data.caseId}}</strong></div>
    <div i18n="Translation.Notes@@files-are-being-uploaded">Files are being uploaded...</div>

    <div *ngFor="let fileUpload of toast.data.caseFileUploadStatus.uploads" class="file-upload-container">
      <div [ngSwitch]="fileUpload.file.key">
        <ng-container *ngSwitchCase="'vcf'">VCF file</ng-container>
        <ng-container *ngSwitchCase="'bam'">BAM file</ng-container>
        <ng-container *ngSwitchDefault><app-biomarker-reader-file [biomarkerFileReader]="fileUpload.file.options.biomarkerFileReader"></app-biomarker-reader-file></ng-container>
      </div>
      <mat-progress-bar *ngIf="fileUpload.percentDone | async" mode="determinate" [value]="fileUpload.percentDone | async"></mat-progress-bar>
      <mat-progress-bar *ngIf="!(fileUpload.percentDone | async)" mode="indeterminate"></mat-progress-bar>
    </div>
  </div>

  <!-- case form -->

  <app-ruo-ivd-mapper *ngSwitchCase="'CASE_SAVE_FAILED'">
    <span IVD i18n="Translation.Notes@@unable-to-save-the-case-please-see-the-message-at-the-top-of-this-page">Unable to save the case. Please see the message at the top of this page.</span>
    <span RUO i18n="Translation.Notes@@unable-to-save-the-research-case-please-see-the-message-at-the-top-of-this-page">Unable to save the research case. Please see the message at the top of this page.</span>
  </app-ruo-ivd-mapper>

  <!-- Complex biomarker form -->
  <ng-container *ngSwitchCase="'amplification-title-case'" i18n="Translation.Notes@@amplification-title-case">Amplification</ng-container>
  <ng-container *ngSwitchCase="'deletion-title-case'" i18n="Translation.Notes@@deletion-title-case">Deletion</ng-container>
  <ng-container *ngSwitchCase="'msi-high'" i18n="Translation.Notes@@msi-high">MSI-High</ng-container>
  <ng-container *ngSwitchCase="'msi-intermediate'" i18n="Translation.Notes@@msi-intermediate">MSI-Intermediate</ng-container>
  <ng-container *ngSwitchCase="'msi-equivocal'" i18n="Translation.Notes@@msi-equivocal">MSI-Equivocal</ng-container>
  <ng-container *ngSwitchCase="'msi-low'" i18n="Translation.Notes@@msi-low">MSI-Low</ng-container>
  <ng-container *ngSwitchCase="'mss'" i18n="Translation.Notes@@mss">MSS</ng-container>
  <ng-container *ngSwitchCase="'msi-unknown'" i18n="Translation.Notes@@msi-unknown">MSI-Unknown</ng-container>
  <ng-container *ngSwitchCase="'msi-ambiguous'" i18n="Translation.Notes@@msi-ambiguous">MSI-Ambiguous</ng-container>
  <ng-container *ngSwitchCase="'high-title-case'" i18n="Translation.Notes@@high-title-case">High</ng-container>
  <ng-container *ngSwitchCase="'intermediate-title-case'" i18n="Translation.Notes@@intermediate-title-case">Intermediate</ng-container>
  <ng-container *ngSwitchCase="'low-title-case'" i18n="Translation.Notes@@low-title-case">Low</ng-container>
  <ng-container *ngSwitchCase="'unknown-title-case'" i18n="Translation.Notes@@unknown-title-case">Unknown</ng-container>


  <!-- view case -->
  <span i18n="Translation.Notes@@unable-to-access-network" *ngSwitchCase="'Unable to access network'">Unable to access network</span>
  <span i18n="Translation.Notes@@failed-to-create-lab-note" *ngSwitchCase="'LAB_NOTE_CREATE_FAIL'">Failed to create Lab Note</span>
  <span *ngSwitchCase="'LOADING_REPORT'" ngPreserveWhitespaces><i class="fa fa-spinner fa-pulse fa-fw"></i> <ng-container i18n="Translation.Notes@@generating-report">Generating report...</ng-container></span>
  <span i18n="Translation.Notes@@failed-to-load-the-report" *ngSwitchCase="'REPORT_FAILED'">Failed to load the report</span>
  <span i18n="Translation.Notes@@failed-to-update-unprocessed-variants-display-selection" *ngSwitchCase="'FAILED_UPDATE_UNPROCESSED'">Failed to update unprocessed variants display selection</span>

  <!-- case report -->
  <ng-container *ngIf="(showRuo$ | async); else ivdDisclaimerLine2">
    <span *ngSwitchCase="'DISCLAIMER_LINE_2'" ngPreserveWhitespaces><app-name></app-name> <ng-container i18n="Translation.Notes@@nmp-is-not-able-to-differentiate-between-germline-and-somatic-variants-in-general-variant-interpretations-are-provided-assuming-the-variants-are-of-somatic-origin">(nMP) is not able to differentiate between germline and somatic variants. In general, variant interpretations are provided assuming the variants are of somatic origin.</ng-container></span>
  </ng-container>

  <ng-template #ivdDisclaimerLine2>
    <span *ngSwitchCase="'DISCLAIMER_LINE_2'" ngPreserveWhitespaces><app-name></app-name> <ng-container i18n="Translation.Notes@@is-not-able-to-differentiate-between-germline-and-somatic-variants-in-general-variant-interpretations-are-provided-assuming-the-variants-are-of-somatic-origin">is not able to differentiate between germline and somatic variants. In general, variant interpretations are provided assuming the variants are of somatic origin.</ng-container></span>
  </ng-template>

  <span *ngSwitchCase="'DISCLAIMER_LINE_3'" ngPreserveWhitespaces>
    <app-name></app-name> <ng-container i18n="Translation.Notes@@provided-by-roche-diagnostics-solutions-is-intended-for-research-use-only">provided by Roche Sequencing Solutions, Inc. is intended for research-use-only (RUO) purposes. The information displayed by nMP is solely for RUO purposes, not to be used in any clinical diagnostic practice, and does not constitute a claim that nMP is anything other than a RUO tool.</ng-container>
  </span>

  <!-- variant alias -->
  <span i18n="Translation.Notes@@variant-alias-deleted" *ngSwitchCase="'VARIANT_ALIAS_DELETED'">Variant alias deleted</span>
  <span i18n="Translation.Notes@@variant-alias-saved" *ngSwitchCase="'VARIANT_ALIAS_SAVED'">Variant alias saved</span>
  <span i18n="Translation.Notes@@unable-to-save-variant-alias" *ngSwitchCase="'VARIANT_ALIAS_SAVE_ERROR'">Unable to save variant alias</span>
  <span i18n="Translation.Notes@@unable-to-delete-variant-alias" *ngSwitchCase="'VARIANT_ALIAS_DELETE_ERROR'">Unable to delete variant alias</span>

  <!-- variant detail -->
  <ng-container i18n="Translation.Notes@@gain" *ngSwitchCase="'gain`'">gain</ng-container>
  <ng-container i18n="Translation.Notes@@loss" *ngSwitchCase="'loss'">loss</ng-container>
  <ng-container i18n="Translation.Notes@@deleted" *ngSwitchCase="'DELETED'">deleted</ng-container>
  <ng-container i18n="Translation.Notes@@disrupted" *ngSwitchCase="'DISRUPTED'">disrupted</ng-container>
  <ng-container i18n="Translation.Notes@@duplicated" *ngSwitchCase="'DUPLICATED'">duplicated</ng-container>
  <ng-container i18n="Translation.Notes@@intact" *ngSwitchCase="'INTACT'">intact</ng-container>
  <ng-container i18n="Translation.Notes@@not-applicable-small-caps" *ngSwitchCase="'NOT_APPLICABLE'">not applicable</ng-container>

  <!-- case list (+closed) -->
  <app-ruo-ivd-mapper *ngSwitchCase="'You do not have permission to view the report.'">
    <span IVD i18n="Translation.Notes@@you-do-not-have-permission-to-view-the-report">You do not have permission to view the report.</span>
    <span RUO i18n="Translation.Notes@@you-do-not-have-permission-to-view-the-analysis-report">You do not have permission to view the analysis report.</span>
  </app-ruo-ivd-mapper>
  <span i18n="Translation.Notes@@you-do-not-have-permission-to-edit" *ngSwitchCase="'You do not have permission to edit.'">You do not have permission to edit.</span>

  <!-- case list actions -->
  <app-ruo-ivd-mapper *ngSwitchCase="'Unable to cancel case'">
    <span IVD i18n="Translation.Notes@@unable-to-cancel-case">Unable to cancel case</span>
    <span RUO i18n="Translation.Notes@@unable-to-cancel-research-case">Unable to cancel research case</span>
  </app-ruo-ivd-mapper>
  <app-ruo-ivd-mapper *ngSwitchCase="'Unable to delete case'">
    <span IVD i18n="Translation.Notes@@unable-to-delete-case">Unable to delete case</span>
    <span RUO i18n="Translation.Notes@@unable-to-delete-research-case">Unable to delete research case</span>
  </app-ruo-ivd-mapper>

  <!-- assay form -->
  <span i18n="Translation.Notes@@unable-to-save-assay" *ngSwitchCase="'Unable to save assay'">Unable to save assay</span>
  <span i18n="Translation.Notes@@assay-saved" *ngSwitchCase="'Assay saved'">Assay saved</span>
  <span i18n="Translation.Notes@@failed-to-determine-file-format" *ngSwitchCase="'SECONDARY_FORMAT_FAIL'">Failed to determine file format</span>
  <span i18n="Translation.Notes@@failed-to-process-the-thesholds-csv-file" *ngSwitchCase="'THRESHOLD_PARSE_FAIL'">Failed to process the thesholds CSV file</span>
  <span i18n="Translation.Notes@@failed-to-migrate-pertinent-negatives" *ngSwitchCase="'FAILED_PERTINENT_NEG_MIGRATE'">Failed to migrate pertinent negatives</span>
  <span i18n="Translation.Notes@@unable-to-load-assay-configuration" *ngSwitchCase="'ASSAY_CONFIG_LOAD_ERROR'">Unable to load assay configuration</span>
  <span i18n="Translation.Notes@@invalid-file" *ngSwitchCase="'INVALID_FILE'">Invalid file</span>
  <span i18n="Translation.Notes@@invalid-headers" *ngSwitchCase="'INVALID_HEADERS'">Invalid headers</span>
  <span i18n="Translation.Notes@@missing-disease" *ngSwitchCase="'MISSING_DISEASE'">Missing disease</span>
  <span i18n="Translation.Notes@@unknown-disease" *ngSwitchCase="'UNKNOWN_DISEASE'">Unknown disease</span>
  <span i18n="Translation.Notes@@duplicate-disease" *ngSwitchCase="'DUPLICATE_DISEASE'">Duplicate disease</span>
  <span i18n="Translation.Notes@@invalid-disease" *ngSwitchCase="'INVALID_DISEASE'">Invalid disease</span>
  <span i18n="Translation.Notes@@missing-threshold" *ngSwitchCase="'MISSING_THRESHOLD'">Missing threshold</span>
  <span i18n="Translation.Notes@@invalid-threshold" *ngSwitchCase="'INVALID_THRESHOLD'">Invalid threshold</span>

  <!-- user form -->
  <span i18n="Translation.Notes@@user-has-been-created" *ngSwitchCase="'USER_CREATED'">User has been created</span>
  <span i18n="Translation.Notes@@user-has-been-updated" *ngSwitchCase="'USER_UPDATED'">User has been updated</span>
  <span i18n="Translation.Notes@@cannot-remove-it-admin-role" *ngSwitchCase="'USER_CANT_REMOVE_IT_ROLE'">Cannot remove IT admin role</span>
  <span i18n="Translation.Notes@@this-email-address-has-already-been-added" *ngSwitchCase="'USER_DUPLICATE_EMAIL'">This email address has already been added</span>

  <!-- case approval -->
  <span i18n="Translation.Notes@@unable-to-start-transition" *ngSwitchCase="'Unable to start transition'">Unable to start transition</span>
  <span i18n="Translation.Notes@@unable-to-reject" *ngSwitchCase="'Unable to reject'">Unable to reject</span>
  <span i18n="Translation.Notes@@unable-to-approve-and-sign" *ngSwitchCase="'Unable to approve and sign'">Unable to approve and sign</span>
  <span i18n="Translation.Notes@@unable-to-send-for-approval" *ngSwitchCase="'Unable to send for approval'">Unable to send for approval</span>
  <span *ngSwitchCase="'CASE_TRANSITION_FAIL'" ngPreserveWhitespaces>
    <ng-container i18n="Translation.Notes@@something-went-wrong">Something went wrong.</ng-container>
    <ng-container i18n="Translation.Notes@@please-try-again-after-a-page">Please try again after a page</ng-container>
    <a href="" (click)="data.refreshData($event)" i18n="Translation.Link@@refresh">refresh</a>
  </span>

  <!-- Change diagnosis popup -->
  <app-ruo-ivd-mapper *ngSwitchCase="'CURRENT_CASE'">
    <ng-container i18n="ChangeDiagnosisPopup.Banner.Warning.Content@@current-case" IVD>
      Current case
    </ng-container>
    <ng-container i18n="ChangeDiagnosisPopup.Banner.Warning.Content@@current-research-case" RUO>
      Current research case
    </ng-container>
  </app-ruo-ivd-mapper>

  <ng-container *ngSwitchCase="'PROCESSED_WITH_ASSAY'"
    i18n="ChangeDiagnosisPopup.Banner.Warning.Content@@processed-with-assay">
    was processed with Assay</ng-container>
  <ng-container *ngSwitchCase="'VERSION'" i18n="ChangeDiagnosisPopup.Banner.Warning.Content@@version">version
  </ng-container>
  <ng-container *ngSwitchCase="'BUT'" i18n="ChangeDiagnosisPopup.Banner.Warning.Content@@but">but</ng-container>
  <ng-container *ngSwitchCase="'IS_NOW_AT_VERSION'" i18n="ChangeDiagnosisPopup.Banner.Warning.Content@@now-at-version">
    is now at version
  </ng-container>

  <!-- classification -->
  <span i18n="Translation.Notes@@unable-to-update" *ngSwitchCase="'Unable to update'">Unable to update</span>

  <!-- user actions -->
  <span *ngSwitchCase="'PASSWORD_RESET_SUCCESS'">
    <ng-container i18n="Translation.Notes@@password-has-been-reset-and-an-email-sent-to">Password has been reset and an email sent to</ng-container> {{data.email}}
  </span>
  <span *ngSwitchCase="'LOGOUT_SUCCESS'">
    {{data.email}} <ng-container i18n="Translation.Notes@@has-been-signed-out">has been signed out</ng-container>
  </span>
  <span *ngSwitchCase="'MFA_TOGGLING_SUCCESS'">
    <ng-container i18n="Translation.Notes@@2fa-for">2FA for</ng-container> {{data.email}} <ng-container i18n="Translation.Notes@@has-been">has been</ng-container> {{data.nextState}}
  </span>

  <!-- sex -->
  <span i18n="Translation.Notes@@male" *ngSwitchCase="'MALE'">Male</span>
  <span i18n="Translation.Notes@@female" *ngSwitchCase="'FEMALE'">Female</span>
  <span i18n="Translation.Notes@@other" *ngSwitchCase="'OTHER'">Other</span>
  <span i18n="Translation.Notes@@variant-oncogenicity-Unknown" *ngSwitchCase="'UNKNOWN'">Unknown</span>

  <!-- Organizations -->
  <span i18n="Translation.Notes@@unable-to-generate-report" *ngSwitchCase="'DOWNLOAD_REPORT_FAILURE'">Unable to generate report</span>
  <span i18n="Translation.Notes@@organization-has-been-updated" *ngSwitchCase="'ORGANIZATION_UPDATED'">Organization has been updated</span>
  <span i18n="Translation.Notes@@organization-has-been-created" *ngSwitchCase="'ORGANIZATION_CREATED'">Organization has been created</span>
  <span i18n="Translation.Notes@@unable-to-save-organization" *ngSwitchCase="'ORGANIZATION_SAVE_ERROR'">Unable to save organization</span>
  <span i18n="Translation.Notes@@this-tenant-is-no-longer-active" *ngSwitchCase="'ORGANIZATION_NOT_ACTIVE'">This tenant is no longer active.</span>

  <!-- Datasources -->
  <span i18n="Translation.Notes@@datasource-added-successfully" *ngSwitchCase="'INGESTION_SUCCESSFUL'">Datasource added successfully</span>
  <span i18n="Translation.Notes@@adding-this-datasource-will-take-some-time-please-wait" *ngSwitchCase="'INGESTION_DELAYED'">Adding this datasource will take some time. Please wait</span>
  <span i18n="Translation.Notes@@there-was-a-problem-could-not-add-the-datasource" *ngSwitchCase="'INGESTION_FAILED'">There was a problem. Could not add the datasource</span>

  <!-- Data sharing -->
  <span i18n="Translation.Notes@@unable-to-disable-data-sharing" *ngSwitchCase="'DISABLE_SHARING_FAILED'">Unable to disable data sharing</span>
  <span i18n="Translation.Notes@@unable-to-enable-data-sharing" *ngSwitchCase="'ENABLE_SHARING_FAILED'">Unable to enable data sharing</span>

  <!-- API Access & Export -->
  <span i18n="Translation.Notes@@unable-to-cancel-export" *ngSwitchCase="'CANCEL_EXPORT_FAILURE'">Unable to cancel export</span>
  <span i18n="Translation.Notes@@unable-to-cancel-as-the-download-process-has-already-been-completed" *ngSwitchCase="'CANCEL_EXPORT_FAILURE_EXPORT_FINISHED'">Unable to cancel as the download process has already been completed.</span>

  <!-- Common messages -->
  <span i18n="Translation.Notes@@sending-email" *ngSwitchCase="'SENDING_EMAIL'">Sending email</span>
  <span i18n="Translation.Notes@@default" *ngSwitchCase="'DEFAULT'">Default</span>
  <span i18n="Translation.Notes@@filter" *ngSwitchCase="'FILTER'">filter</span>
  <span i18n="Translation.Notes@@copy-number" *ngSwitchCase="'COPY_NUMBER'">Copy number</span>
  <span i18n="Translation.Notes@@fold-change" *ngSwitchCase="'FOLD_CHANGE'">Fold change</span>

  <!-- Biomarker Type -->
  <span *ngSwitchCase="'SNV'" i18n="Translation.Notes@@snvs">SNVs</span>
  <span *ngSwitchCase="'MNV'" i18n="Translation.Notes@@mnvs">MNVs</span>
  <span *ngSwitchCase="'Indel'" i18n="Translation.Notes@@indels">Indels</span>
  <span *ngSwitchCase="'CopyNumberVariant'" i18n="Translation.Notes@@cnvs">CNVs</span>
  <span *ngSwitchCase="'GeneFusion'" i18n="Translation.Notes@@fusions">Fusions</span>
  <span *ngSwitchCase="'TSO500_GENE_FUSION'" i18n="Translation.Notes@@rna-fusions">RNA Fusions</span>
  <span *ngSwitchCase="'TSO500_DNA_GENE_FUSION'" i18n="Translation.Notes@@dna-fusions">DNA Fusions</span>
  <span *ngSwitchCase="'NonGeneFusionRearrangement'" i18n="Translation.Notes@@rearrangements">Rearrangements</span>
  <span *ngSwitchCase="'SmallVariant'" i18n="Translation.Notes@@small-variants">Small variants</span>
  <span *ngSwitchCase="'TMB'" i18n="Translation.Notes@@tmb">TMB</span>
  <span *ngSwitchCase="'MSI'" i18n="Translation.Notes@@msi">MSI</span>
  <span *ngSwitchCase="'MSI_JSD'" i18n="Translation.Notes@@msi-sum-jsd">MSI (sum JSD)</span>
  <span *ngSwitchCase="'HRD'" i18n="Translation.Notes@@hrdsig">HRDsig</span>
  <span *ngSwitchCase="'GenomicLossOfHeterozygosity'" i18n="Translation.Notes@@loh">LOH</span>
  <span *ngSwitchCase="'OtherStructuralRearrangement'" i18n="Translation.Notes@@other-structural-rearrangements">Other structural rearrangements</span>
  <span *ngSwitchCase="'OtherStructuralVariants'" i18n="Translation.Notes@@other-structural-variants">Other structural variants</span>
  <span *ngSwitchCase="'SPLICING_VARIANT'" i18n="Translation.Notes@@splice-variant-title-case">Splice variants</span>

  <!-- RUO Message -->
  <span i18n="RUO message displayed across the application@@for-research-use-only-not-for-use-in-diagnostic-procedures" *ngSwitchCase="'RUO_MESSAGE'">For research use only. Not for use in diagnostic procedures.</span>

  <!-- Third Party Attributions -->
  <ng-container *ngSwitchCase="'THIRD_PARTY_ATTR'">
    <p i18n="Translation.Paragraph@@a-portion-of-the-somatic-gene-variant-annotations-and-related-content-have-been-provided-by-the-jackson-laboratory-clinical-knowledgebase-jax-ckb">A portion of the somatic gene variant annotations and related content have been provided by The Jackson Laboratory Clinical Knowledgebase (JAX-CKB™)</p>
    <p i18n="Translation.Paragraph@@national-comprehensive-cancer-network-2018-all-rights-reserved-national-comprehensive-cancer-network-nccn-nccn-guidelines-nccn-compendium-nccn-templates-nccn-flash-updates-nccn-guidelines-for-patients-and-powered-by-nccn-are-trademarks-of-national-comprehensive-cancer-network-inc">© National Comprehensive Cancer Network 2018, All Rights Reserved. NATIONAL COMPREHENSIVE CANCER NETWORK®, NCCN®, NCCN GUIDELINES®, NCCN COMPENDIUM®, NCCN TEMPLATES®, NCCN FLASH UPDATES™, NCCN GUIDELINES FOR PATIENTS® and POWERED BY NCCN® are trademarks of National Comprehensive Cancer Network, Inc.</p>
    <p i18n="Translation.Paragraph@@referenced-with-permission-from-the-nccn-clinical-practice-guidelines-in-oncology-nccn-guidelines-to-view-the-most-recent-and-complete-version-of-the-guidelines-go-online-to-nccn-org-nccn-makes-no-warranties-of-any-kind-whatsoever-regarding-their-content-use-or-application-and-disclaims-any-responsibility-for-their-application-or-use-in-any-way">Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®). To view the most recent and complete version of the guidelines, go online to NCCN.org. NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.</p>
    <ng-container *ngIf="(showRuo$ | async); else ivdThirdPartyAttributions">
      <p i18n="Translation.Paragraph@@the-nccn-guidelines-and-other-content-provided-by-nccn-are-works-in-progress-that-may-be-refined-as-often-as-new-significant-data-becomes-available-they-are-statements-of-consensus-of-its-authors-regarding-their-views-of-currently-accepted-approaches-to-treatment">
        The NCCN Guidelines® and other content provided by NCCN are works in progress that may be refined as often as new significant data becomes available. They are statements of consensus of its authors regarding their views of currently accepted approaches to treatment.
      </p>
    </ng-container>

    <ng-template #ivdThirdPartyAttributions>
      <p i18n="Translation.Paragraph@@the-nccn-guidelines-and-other-content-provided-by-nccn-are-works-in-progress-that-may-be-refined-as-often-as-new-significant-data-becomes-available-they-are-statements-of-consensus-of-its-authors-regarding-their-views-of-currently-accepted-approaches-to-treatment-any-clinician-seeking-to-apply-or-consult-any-nccn-guidelines-or-other-nccn-content-is-expected-to-use-independent-medical-judgment-in-the-context-of-individual-clinical-circumstances-to-determine-any-patient-s-care-or-treatment-the-national-comprehensive-cancer-network-makes-no-warranties-of-any-kind-whatsoever-regarding-their-content-use-or-application-and-disclaims-any-responsibility-for-their-application-or-use-in-any-way">The NCCN Guidelines® and other content provided by NCCN are works in progress that may be refined as often as new significant data becomes available. They are statements of consensus of its authors regarding their views of currently accepted approaches to treatment. Any clinician seeking to apply or consult any NCCN Guidelines® or other NCCN Content is expected to use independent medical judgment in the context of individual clinical circumstances to determine any patient's care or treatment. The National Comprehensive Cancer Network makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.</p>
    </ng-template>

    <p i18n="Translation.Paragraph@@clinical-trial-matching-based-on-reported-biomarkers-are-provided-by-molecularmatch">Clinical trial matching based on reported biomarkers are provided by MolecularMatch.</p>

    <p *ngIf="(showRuo$ | async)" i18n="Translation.Paragraph@@the-content-presented-in-this-application-is-intended-for-RUO-purposes-only.">The content presented in this application is intended for RUO purposes only.</p>
  </ng-container>

  <!-- Bulk Reclassify message-->
  <span *ngSwitchCase="'BULK_RECLASSIFY'" ngPreserveWhitespaces>
    <span *ngIf="!data.isRemove">
      <span i18n="Translation.Notes@@classification-of-the-selected-biomarkers-was-changed">Classification of the selected biomarkers was changed.</span> <span i18n="@@no-of-biomarkers">No. of biomarkers: </span>{{data.count}}, <span i18n="@@new-classification">new classification: </span><app-tier-name [value]="data.tier"></app-tier-name>.
    </span>
    <span *ngIf="data.isRemove;"><span i18n="Translation.Notes@@selected-biomarkers-were-removed-from-the-report-no-of-biomarkers">Selected biomarkers were removed from the report. No. of biomarkers: </span>{{data.count}}.</span>
  </span>
  <span *ngSwitchCase="'BULK_RECLASSIFY_ERROR'" i18n="Translation.Notes@@unable-to-perform-bulk-edit">Unable to perform bulk edit</span>

  <!-- Feature Flag message-->
  <span *ngSwitchCase="'FEATURE_FLAG_TOGGLING_SUCCESS'">
    <ng-container i18n="Translation.Notes@@the-status-of-feature-flag">The status of feature flag: </ng-container> {{data.featureName}}  <ng-container i18n="@@has-been-changed-successfully"> has been changed successfully.</ng-container>
  </span>

  <!-- Bulk edit: Custom column names -->
  <span *ngSwitchCase="'Variant'" i18n="Translation.Notes@@variant">Variant</span>
  <span *ngSwitchCase="'Tier'" i18n="Translation.Notes@@tier">Tier</span>
  <span *ngSwitchCase="'VAF'" i18n="Translation.Notes@@vaf">VAF</span>
  <span *ngSwitchCase="'RD'" i18n="Translation.Notes@@rd">RD</span>
  <span *ngSwitchCase="'ClinVar'" i18n="Translation.Notes@@clinvar">ClinVar</span>
  <span *ngSwitchCase="'Clinical significance'" i18n="Translation.Notes@@clinical-significance">Clinical significance</span>
  <span *ngSwitchCase="'Origin'" i18n="Translation.Notes@@origin">Origin</span>
  <span *ngSwitchCase="'Number of submissions'" i18n="Translation.Notes@@number-of-submissions">Number of submissions</span>
  <span *ngSwitchCase="'Population frequency database'" i18n="Translation.Notes@@population-frequency-database">Population frequency database</span>
  <span *ngSwitchCase="'Allele frequency'" i18n="Translation.Notes@@allele-frequency">Allele frequency</span>
  <span *ngSwitchCase="'Tags'" i18n="Translation.Notes@@tags">Tags</span>
  <span *ngSwitchCase="'Annotation databases'" i18n="Translation.Notes@@annotation-databases">Annotation databases</span>
  <span *ngSwitchCase="'# reads'" i18n="Number of supporting reads|header for # reads@@reads-column-header"># reads</span>

  <!-- App settings page -->
  <!-- Decimal place lists -->
  <ng-container i18n="Translation.Notes@@period" *ngSwitchCase="DecimalPlace.PERIOD">period</ng-container>
  <ng-container i18n="Translation.Notes@@comma" *ngSwitchCase="DecimalPlace.COMMA">comma</ng-container>

  <!-- Api access key restrictions range -->
  <ng-container i18n="Translation.Notes@@single" *ngSwitchCase="IPAddressRangeType.single">single</ng-container>
  <ng-container i18n="Translation.Notes@@range" *ngSwitchCase="IPAddressRangeType.range">range</ng-container>

  <!-- Thousand separator -->
  <ng-container i18n="Translation.Notes@@space" *ngSwitchCase="ThousandSeparator.SPACE">space
  </ng-container>
  <ng-container i18n="Translation.Notes@@apostrophe" *ngSwitchCase="ThousandSeparator.APOSTROPHE">
    apostrophe
  </ng-container>

  <!-- Language ingestion page -->
  <ng-container *ngSwitchCase="'LANGUAGE_INGESTION_SUCCESS'" i18n="LanguageIngestionPage.Msg@@language-ingestion-success">
    Language ingestion completed. Go to app settings to select the language.
  </ng-container>
  <ng-container *ngSwitchCase="'LANGUAGE_INGESTION_FAILED'" i18n="LanguageIngestionPage.Msg@@language-ingestion-failed">
    Language ingestion failed. Common reasons are: Invalid checksum or invalid file uri or language already exists.
  </ng-container>

  <!-- missing i18n
       Also this will be hit by some toasts that show a server error
  -->
  <span *ngSwitchDefault>{{translationKey}}</span>
</ng-container>
